<template>
  <v-dialog
    v-model="eventListPopup"
    max-width="500"
    :fullscreen="smAndDown"
    transition="dialog-bottom-transition"
  >
    <v-card class="py-1">
      <v-card-title class="d-flex align-center">
        我的活动
        <v-spacer></v-spacer>
        <v-btn icon variant="plain" @click="eventListPopup = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div class="event-search-wrapper">
        <v-text-field
          v-model="search"
          label="搜索活动"
          variant="outlined"
          prepend-inner-icon="mdi-magnify"
          hide-details
          single-line
          density="comfortable"
          class="pa-2"
        ></v-text-field>
      </div>
      <div
        v-if="!loadingEventList && eventList && eventList.length > 0"
        class="event-list-wrapper"
      >
        <v-card
          v-for="event in eventList"
          :key="event.id"
          class="pa-1 mx-2 my-1 d-flex event-list-card"
          @click="changeEvent(event)"
        >
          <v-row dense>
            <v-col cols="12" md="5">
              <v-img
                v-if="event.featured_image"
                :src="event.featured_image.sizes.thumbnail.url"
                :height="smAndDown ? 100 : '100%'"
                class="rounded-lg event-list-card--image"
                cover
              ></v-img>
            </v-col>
            <v-col cols="12" md="7">
              <h5 class="text-h5">{{ event.title }}</h5>
              <span class="desc">{{
                formatDateTime(new Date(event.date_started))
              }}</span>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <v-card
        v-if="!loadingEventList && eventList && eventList.length == 0"
        class="pa-2 event-list-card"
      >
        <v-row dense>
          <v-col cols="12" class="text-center">
            <v-icon size="50">mdi-calendar-remove</v-icon>
            <span>没有找到活动</span>
          </v-col>
        </v-row>
      </v-card>
      <!-- loading -->
      <v-card v-if="loadingEventList" class="pa-2 event-list-card">
        <v-row dense>
          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
// 活动列表
import { stringify } from "qs";
import { storeToRefs } from "pinia";
import { useDisplay } from "vuetify";
import { useUserStore } from "@/store/SiteUser";
import { useEventObjectStore } from "@/store/EventObject";
import type { events } from "@/types";
import type { Event, User } from "@/types/payload-types";
const eventStore = useEventObjectStore();
const { smAndDown } = useDisplay();
const loadingEventList = ref(false);
const eventListPopup = ref(false);
const eventList = ref([] as Event[]);
const search = ref("");

function formatDateTime(date: Date) {
  if (new Date(date).toLocaleDateString("zh-CN") !== "Invalid Date") {
    return new Date(date).toLocaleDateString("zh-CN", {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    });
  } else {
    return "";
  }
}

async function openEventListPopup() {
  loadingEventList.value = true;
  const userStore = useUserStore();
  const userData = storeToRefs(userStore);
  const user = ref(userData.user as unknown as User);
  const config = useRuntimeConfig();
  const payloadUrl = config.public.payloadUrl;

  if (!user.value) {
    return;
  }

  const query = {
    and: [
      {
        or: [
          {
            createdBy: {
              equals: user.value.id,
            },
          },
          {
            "organizers.user_id": {
              contains: user.value.id,
            },
          },
        ],
      },
      {
        title: {
          contains: search.value,
        },
      },
    ],
  };

  const queryStr = stringify(
    {
      where: query,
      depth: 3,
    },
    { addQueryPrefix: true },
  );

  eventListPopup.value = true;
  if (search.value) {
    await $fetch(payloadUrl + `/api/events${queryStr}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((res) => {
        eventList.value = [];
        eventList.value = res.docs as events[];
        loadingEventList.value = false;
      })
      .catch(() => {
        eventList.value = [];
        loadingEventList.value = false;
      });
  } else {
    await $fetch(payloadUrl + `/api/events${queryStr}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((res) => {
        eventList.value = [];
        // eventList.value = res.docs as events[];
        eventList.value = res.docs as events[];
        loadingEventList.value = false;
      })
      .catch(() => {
        eventList.value = [];
        loadingEventList.value = false;
      });
  }
}

const searchTimer = ref<NodeJS.Timeout | null>(null);
watch(search, () => {
  if (searchTimer.value) {
    clearTimeout(searchTimer.value);
  }
  searchTimer.value = setTimeout(() => {
    openEventListPopup();
  }, 500);
});

function changeEvent(event: Event) {
  const router = useRouter();
  eventStore.setData(event);
  eventListPopup.value = false;
  search.value = "";
  router.push(`/events/manage/${event.id}`);
}

defineExpose({
  openEventListPopup,
});
</script>
